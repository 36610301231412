import { type I13NSec } from "@yahoo-creators/i13n";

export const COVER_IMAGE_SIZE = "2560x440|1|100";
export const LOGO_IMAGE_SIZE = "480x480|1|100";

export const BRAND_COLORS: {
  [key: string]: string;
} = {
  barney: "#CC008C",
  bonsai: "#00873c",
  grapeJelly: "#6001D2", // fallback color
  scooter: "#0063EB",
  soloCup: "#EB0F29",
  starfish: "#7759FF",
};

export const BRAND_HEADER_SEC: I13NSec = "brand-header";
export const BRAND_PAGE_PT = "content";
export const BRAND_STREAM_SEC: I13NSec = "brand-stream";
export const CREATOR_BRAND_SUBSEC = "creator-brand";
export const CREATOR_DIRECTORY_SUBSEC = "creator-directory";
export const CREATOR_HUB_PT = "minihome";
export const CREATOR_HUB_SUBSEC = "creator-hub";
export const CREATOR_HUB_URL = "https://creators.yahoo.com/";
export const CREATOR_DIRECTORY_PT = "utility";

export const CREATORS_PROD_DOMAIN = "creators.yahoo.com";

export const DISCOVERY_HUB_PATH = "/";

export const CREATOR_PAGES_REGEX = new RegExp("(?:/([\\w-]*))?");
export const CREATOR_DIRECTORY_BRAND_REGEX = new RegExp("[^a-zA-Zs]", "g");
